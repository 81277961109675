import React from "react";
import styled from "@emotion/styled";

import { Box, Typography, Grid } from "@mui/material";
import CentronLogo from "./svg/CentronLogo.svg";

export default function AboutTheBuilder(props) {
  return (
    <Box bgcolor={props?.theme?.palette?.primary?.main} width="100%" align="center">
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        p={{ xs: "5rem 2rem", md: "9rem 2rem", lg: "9rem 0" }}
        maxWidth={props?.theme?.breakpoints?.values?.lg}
      >
        <Grid
          item
          xs={12}
          md={8}
          pr={{ xs: 0, md: "6%" }}
          pb={{ xs: "4rem", md: 0 }}
          sx={{
            borderRight: { xs: "", md: "solid 3px white" },
            borderBottom: { xs: "solid 3px white", md: "unset" },
          }}
        >
          <Typography align="left" variant="h3" color={props?.theme?.palette?.secondary?.main}>
            About the Developer and Builder
          </Typography>
          <Typography align="left" variant="body1" color={"white"}>
            For over 39 years, Centron has been one of the most prominent and well-established
            developers of real estate across Western Canada. Founded in 1984, Centron’s track record
            includes millions of square feet of real estate projects, from office, industrial, and
            retail to hotel and community developments like Clearwater Park. Centron has earned its
            reputation for client commitment and a steadfast focus on quality, care and client
            satisfaction.
          </Typography>
          <Typography align="left" variant="body1" color={"white"}>
            Clearwater Park will offer residents two rental opportunities, unique among Alberta’s
            new communities. Bellmore Cove and Water’s Edge will be designed by Centron’s
            award-winning development and construction team. Bellmore Cove is a split-level home
            project and Water’s Edge will offer single-family home rentals. Register today to ensure
            you receive the latest updates on Clearwater Park or contact a member of the Centron
            team.
          </Typography>
        </Grid>
        <Grid
          align="left"
          xs={12}
          md={3}
          item
          container
          pt={{ xs: "4rem", sm: 0 }}
          justifyContent={"space-evenly"}
        >
          <LogoWrapper src={CentronLogo} alt="Centron Logo" role="presentation" />
        </Grid>
      </Grid>
    </Box>
  );
}
const LogoWrapper = styled.img`
  width: 174px;
  height: auto;
  margin-bottom: 2rem;
  margin-top: 2rem;

  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 200px;
    margin-top: 0;
  }
`;
const ItalicYellow = styled("span")`
  text-transform: uppercase;
  font-weight: 800;
  font-style: italic;
  font-family: "Bookmania", sans-serif;
  color: ${props => props.theme.palette.secondary.main};
`;
