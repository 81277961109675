import React from "react";
import styled from "@emotion/styled";

import { Typography, Grid, Container, Button } from "@mui/material";

import MailChimpForm from "./MailChimpForm/index";

export default function Registration(props) {
  const [messageSent, setMessageSent] = React.useState(false);

  return (
    <Wrapper
      messageSent={messageSent}
      justifyContent="center"
      alignItems="center"
      direction="column"
      container
      p={{ xs: "2rem 1rem 5rem 1rem", md: "5rem 5rem 8rem" }}
    >
      {!messageSent && (
        <Typography variant="h3" component={Container}>
          REGISTER FOR UPDATES
        </Typography>
      )}
      {!messageSent && (
        <Typography variant="body1" component={Container}>
          Welcome to Clearwater Park. Our community will be launching in winter 2025 with a range of
          housing types and styles from Calgary’s best homebuilders. Please register to receive
          updates on our construction progress, community news and the opening of showhomes.
        </Typography>
      )}

      {messageSent && (
        <>
          <Typography align="center" style={{ marginBottom: 0 }} variant="h1">
            Awesome!
          </Typography>
          <Typography align="center" variant="h2">
            We'll be in touch.
          </Typography>
          <Typography align="center" variant="h3">
            Check your email for a confirmation link from us.
          </Typography>
          <Typography align="center" variant="h3">
            You'll need to confirm your registration to complete the process.
          </Typography>
        </>
      )}
      {!messageSent && <MailChimpForm setMessageSent={setMessageSent} />}
    </Wrapper>
  );
}

const Wrapper = styled(Grid)`
  background-color: rgba(234, 239, 238, 1);

  .MuiTypography-body1 {
    max-width: ${({ theme }) => theme.breakpoints.values.md}px;
    text-align: center;
  }

  .MuiTypography-h3 {
    text-align: center;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    .MuiTypography-body1 {
      max-width: ${({ theme }) => theme.breakpoints.values.md}px;
      text-align: center;
    }
    .MuiTypography-h3 {
      text-align: center;
    }
  }
`;
