import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@mui/material";
import { ThemeProvider, responsiveFontSizes } from "@mui/material";

//clearwater

const titleFont = "'Bookmania', sans-serif";
const font = "'Montserrat',sans-serif";

const defaultTheme = responsiveFontSizes(
  createMuiTheme({
    maxWidth: "1920px",
    activeColor: "#6098AF",
    palette: {
      primary: {
        main: "rgba(22,64,62, 1)",
        dark: "rgba(23, 40, 56, 1)",
        light: "rgba(95,132,125, 1)",
      },
      secondary: {
        main: "rgba(247,196,97,1)",
      },

      action: {
        //This is a reference. You can use theme.palette.action.active etc.
        // active: "rgba(192, 202, 200, 0.54)",
        // hover: "rgba(37,95,126,1)",
        // hoverOpacity: 1,
        // selected: "rgba(192, 202, 200, 0.54)",
        // selectedOpacity: 0.08,
        // disabled: "rgba(0, 0, 0, 0.26)",
        // disabledBackground: "rgba(0, 0, 0, 0.12)",
        // disabledOpacity: 0.38,
        // focus: "rgba(0, 0, 0, 0.12)",
        // focusOpacity: 0.12,
        // activatedOpacity: 0.12,
      },
      text: {
        primary: "rgba(144,144,144, 1)",
        secondary: "rgba(55, 93, 91, 1)",
        disabled: "rgba(0, 0, 0, 0.38)",
        hint: "rgba(0, 0, 0, 0.38)",
      },
    },
    typography: {
      htmlFontSize: 16,

      // fontSize: 12,
    },
  })
);

export const theme = responsiveFontSizes(
  createMuiTheme({
    ...defaultTheme,
    overrides: {
      // Style sheet name
      MuiIconButton: {
        //this will change the background of the button
        colorPrimary: {
          "&:hover": {
            backgroundColor: defaultTheme.palette.primary.hover,
            color: defaultTheme.palette.common.white,
          },
        },
      },
      MuiButton: {
        //this will change the background of the button
        root: {
          "&:hover": {
            backgroundColor: defaultTheme.palette.common.white,
          },
        },
        // Name of the rule
        label: {
          // Some CSS
          fontSize: "1rem",
          fontFamily: titleFont,
          textTransform: "uppercase",

          // "&:hover": {
          //   color: defaultTheme.palette.primary.light,
          // },
        },

        containedPrimary: {
          backgroundColor: defaultTheme.palette.primary.main,
          color: defaultTheme.palette.primary.contrastText,
          "&:hover": {
            backgroundColor: "rgba(168, 53, 58, 1)",
          },
          "&:visited": {
            backgroundColor: defaultTheme.palette.primary.active,
          },
        },
        outlined: {
          color: defaultTheme.palette.primary.main,
          "&:hover": {
            backgroundColor: defaultTheme.palette.primary.active,
          },
        },
      },
      MuiInputBase: {
        root: {
          color: "#fff",
          // fontSize: ".5rem",
          // height: "1.25rem",
        },
      },
      MuiOutlinedInput: {
        root: {
          "&:hover $notchedOutline": {
            borderColor: defaultTheme.palette.primary.main,
          },
        },

        input: {},
        notchedOutline: { borderColor: defaultTheme.palette.primary.light },
      },
      MuiInputLabel: {
        root: {
          color: "#fff",
        },
      },

      MuiFormLabel: {
        root: {
          fontSize: "1.2rem",

          // color: "red",
        },
      },
    },
    typography: {
      poster: {
        fontSize: "4rem",
        color: "red",
      },
      h1: {
        fontSize: "4.5rem",
        fontFamily: titleFont,
        fontWeight: "normal",

        // [defaultTheme.breakpoints.up("sm")]: {
        //   fontSize: "2.0rem",
        // },
        // [defaultTheme.breakpoints.up("md")]: {
        //   fontSize: "3.0rem",
        // },
        // [defaultTheme.breakpoints.up("lg")]: {
        //   fontSize: "3.5rem",
        // },
        // [defaultTheme.breakpoints.up("xl")]: {
        //   fontSize: "5rem",
        // },
      },
      h2: {
        fontFamily: titleFont,
        fontWeight: 700,
      },
      h3: {
        // whiteSpace: "nowrap",
        fontSize: "1.4375rem",
        fontFamily: titleFont,
        color: defaultTheme.palette.text.secondary,
        fontWeight: 400,
        lineHeight: "1.4",
        textTransform: "uppercase",
        letterSpacing: ".48px",
        marginBottom: "2rem",
        // paddingBottom: "2rem",
        // [defaultTheme.breakpoints.up("sm")]: {
        //   fontSize: "1.5rem",
        // },
        // [defaultTheme.breakpoints.up("md")]: {
        //   fontSize: "1.8rem",
        // },
        // [defaultTheme.breakpoints.up("lg")]: {
        //   fontSize: "2.0rem",
        // },
      },

      h4: {
        fontSize: "1.3rem",
        fontFamily: titleFont,
        fontWeight: 700,
      },
      h5: {
        // whiteSpace: "nowrap",
        fontSize: "1.4107142857142856rem",

        fontFamily: titleFont,
        fontWeight: 700,
        [defaultTheme.breakpoints.up("sm")]: {
          fontSize: "1.2rem",
        },
        [defaultTheme.breakpoints.up("md")]: {
          fontSize: "1.6867rem",
        },
        [defaultTheme.breakpoints.up("lg")]: {
          fontSize: "1.4rem",
        },
      },
      h6: {
        fontFamily: titleFont,
        fontWeight: 700,
        // whiteSpace: "nowrap",
      },
      body1: {
        maxWidth: defaultTheme.breakpoints.values.md,
        lineHeight: "2.5",
        fontWeight: 400,
        fontSize: ".875rem",
        fontFamily: font,
        color: defaultTheme.palette.text.primary,
        marginBottom: "2rem",
      },
      address: {
        maxWidth: defaultTheme.breakpoints.values.md,
        lineHeight: "2.5",
        fontWeight: 400,
        fontSize: ".875rem",
        fontFamily: font,
        color: defaultTheme.palette.text.primary,
      },
      body2: {
        maxWidth: defaultTheme.breakpoints.values.md,
        lineHeight: "1.7",
        fontWeight: 500,
        fontSize: "1rem",
        fontFamily: titleFont,
      },
      aside: {
        fontSize: "1rem",
        fontFamily: titleFont,
        marginBottom: ".75rem",
      },
    },
  })
  // { factor: 3 }
);
