import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import styled from "@emotion/styled";
import HeroImage from "./images/HeroDesktop.webp";
import NavLogo from "./svg/navLogo.svg";

import HeroMaskLarge from "./svg/heroMaskLarge.svg";
import HeroMaskSmall from "./svg/heroMaskSmall.svg";

export default function Hero({ isSmall, ...props }) {
  return (
    <Wrapper
      maxWidth={"xl"}
      width="100vw"
      height={{ xs: 578, sm: 280, md: 400, lg: 580 }}
      component={Grid}
      container
      direction="column"
      justifyContent={isSmall ? "flex-end" : "space-evenly"}
      alignItems="space-between"
      pl={{ xs: "1rem", md: "5rem" }}
      pr={{ xs: "1rem", md: 0 }}
      pb={{ xs: "3rem", sm: 0 }}
    >
      {!isSmall && <img id="logo" src={NavLogo} alt="Clearwater Park" />}
      <Grid item marginLeft={{ md: "4.5rem" }} align="center">
        {isSmall && <img id="logo" src={NavLogo} alt="Clearwater Park" />}
        {!isSmall && (
          <Typography
            variant="h1"
            color={"common.white"}
            sx={{
              zIndex: 1,
              fontSize: { xs: "2.3rem", sm: "2.5rem", md: "3.2rem", lg: "4.7rem", xl: "4.7rem" },
              lineHeight: 0.96,
              textAlign: { xs: "center", md: "left" },
            }}
          >
            New Residential <br />
            Rentals
          </Typography>
        )}
        {isSmall && (
          <Typography
            variant="h1"
            color={"common.white"}
            sx={{
              zIndex: 1,
              fontSize: { xs: "1.8rem", sm: "2.5rem", md: "3.2rem", lg: "4.7rem", xl: "4.7rem" },
              lineHeight: 0.96,
              textAlign: { xs: "center", md: "left" },
            }}
          >
            New Residential Rentals
          </Typography>
        )}
        <Typography
          variant="body1"
          color={"secondary.main"}
          sx={{
            zIndex: 1,
            fontSize: { xs: "1.9rem", sm: "1.2rem", md: "1.4rem", lg: "1.9rem", xl: "2.5rem" },
            lineHeight: { xs: "1.2", sm: "inherit" },
            textAlign: { xs: "center", md: "left" },
            marginBottom: { xs: "unset" },
            maxWidth: "unset",
          }}
        >
          in Chestermere!
        </Typography>
      </Grid>
      <TestFloatingBox src={isSmall ? HeroMaskSmall : HeroMaskLarge} />
    </Wrapper>
  );
}
const TestFloatingBox = styled.img`
  position: absolute;

  left: 0;
  top: 0;
  width: 100%;
  height: auto;

  // overflow: hidden;
  // mask-image: url(${HeroMaskSmall});
  // mask-repeat: no-repeat;
  // mask-position: 0 40px;
  z-index: 0;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    width: auto;
    height: 100%;
    right: 0;
    left: initial;
    top: 0;
    // mask-image: url(${HeroMaskLarge});
    // mask-position: top;
  }
`;

const Wrapper = styled(Box)`
  background-color: rgb(22, 64, 62);
  position: relative;
  display: flex;
  overflow: hidden;
  h1 {
    text-shadow: 0 0 1px rgb(0, 0, 0, 0.4);
    letter-spacing: normal;
  }
  #logo {
    width: 157px;
    margin-bottom: 1rem;
    height: auto;
    ${({ theme }) => theme.breakpoints.up("md")} {
      margin-bottom: unset;
      width: 254px;
    }
  }
`;
