import React, { useEffect } from "react";
import { Container, useMediaQuery, useTheme } from "@mui/material";

import "./App.css";
import Hero from "./Hero";

import Floorplans from "./Floorplans";
import Registration from "./Registration";
import ImageTextSection from "./ImageTextSection";
import Video from "./Video";
import AboutTheBuilder from "./AboutTheBuilder";
import Footer from "./Footer";
import BellmoreCoveRendering from "./images/BellmoreCoveRendering.webp";
import ClearwaterLogoWhite from "./svg/ClearwaterLogoWhite.svg";
import { initGA } from "./Tracking/index";
function App() {
  useEffect(() => {
    initGA("G-0FZ1YE9BD7");
  }, []);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Container className="App" maxWidth={"xl"} disableGutters>
      <Hero isSmall={isSmall} />
      <Floorplans /> {/* includes Description */}
      <Registration />
      <ImageTextSection
        image={BellmoreCoveRendering}
        logo={ClearwaterLogoWhite}
        alt="Bellmore Cove rendering"
        theme={theme}
      />
      <Video />
      <AboutTheBuilder theme={theme} />
      <Footer />
    </Container>
  );
}

export default App;
