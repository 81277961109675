import React from "react";
import { Typography, Button, Container, Grid } from "@mui/material";
export default function Description(props) {
  return (
    <Grid
      justifyContent="center"
      alignItems="center"
      direction="column"
      container
      p={{ xs: "5rem 2rem 1rem", md: "5rem 5rem 0" }}
      sx={{ minHeight: 500 }}
    >
      <Typography
        variant="h3"
        color={"text.secondary"}
        sx={{ textAlign: { xs: "left", md: "center" } }}
      >
        BELLMORE COVE RESIDENTIAL HOMES FOR LEASE
        <br />
        COMING WINTER 2025
      </Typography>
      <Typography
        variant="body1"
        color={"text.primary"}
        sx={{ textAlign: { xs: "left", md: "center" } }}
      >
        Bellmore Cove – exciting new homes for lease, located in Clearwater Park at Chestermere
        Lake. Bellmore Cove is strategically situated near Clearwater Park’s retail amenities and
        within walking distance from community parks and pathways. Residents at Bellmore Cove will
        also enjoy the Clearwater Park Community Clubhouse, an ideal place where individuals and
        families can enjoy the many amenities that Clearwater Park will offer.
      </Typography>
      <Typography
        variant="body1"
        color={"text.primary"}
        sx={{ textAlign: { xs: "left", md: "center" } }}
      >
        Bellmore Cove includes a total of 69 units, from 500 sq. ft. to over 1,000 sq. ft. - one,
        two and three bedroom options and two different color schemes. Bellmore Cove offers direct
        access into each home (versus an apartment-style building access). Bellmore Cove’s landscape
        plans, both hardscapes and plantings, will add to the aesthetic of these attractive homes.
      </Typography>

      {/* <Button
        color="secondary"
        variant="contained"
        sx={{ borderRadius: 50, padding: "1rem 2rem", fontWeight: 600 }}
      >
        DOWNLOAD FLOORPLAN PDF
      </Button> */}
    </Grid>
  );
}
