export const textFormFields = [
  {
    name: "FNAME",
    label: "First Name",
    required: true,
    type: "text",
  },
  {
    name: "LNAME",
    label: "Last Name",
    required: true,
    type: "text",
  },
  {
    name: "EMAIL",
    label: "Email Address",
    required: true,
    type: "email",
  },
  {
    name: "POSTALCODE",
    label: "Current Postal Code",
    required: true,
    type: "text",
  },
];
export const hiddenFormFields = [
  {
    name: "PROJECT",
    label: "Project",
    required: false,
    type: "hidden",
    value: "Bellmore Cove",
  },
];
export const checkboxFormFields = [
  {
    name: "group[260]",
    label: "What type of home is needed?",
    required: true,

    values: [
      { value: "1", label: "Side by Side", state: "SideBySide" },
      { value: "2", label: "Townhome", state: "Townhome" },
      { value: "4", label: "Laned Home", state: "Laned_Home" },
      { value: "8", label: "Front Drive Home", state: "Front_Drive_Home" },
      { value: "16", label: "Senior Living", state: "Senior_Living" },
      { value: "32", label: "Apartment or Condominium", state: "Apartment_or_Condominium" },
    ],
    row: false,
    style: { maxWidth: 600 },
  },
];
export const radioFormFields = [
  {
    name: "RES_TYPE",
    label: "Are you looking to buy or rent?",
    required: true,
    values: ["Buy", "Rent"],
    row: false,
  },
];
