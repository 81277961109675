import React, { useState } from "react";
import styled from "@emotion/styled";

import axios from "axios";
import {
  TextField,
  Grid,
  Typography,
  Button,
  CircularProgress,
  Alert as MuiAlert,
  Radio,
  RadioGroup,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  FormControl,
  FormLabel,
  FormGroup,
  Container,
} from "@mui/material";
import { textFormFields, checkboxFormFields, radioFormFields, hiddenFormFields } from "./data";
import { validateForm } from "./validateForm";
import ReCAPTCHA from "react-google-recaptcha";
import { Event } from "../Tracking";

function MailChimpForm({ open, setOpen, setMessageSent, ...props }) {
  const [sending, setSending] = useState(false);
  const [reCaptchaValid, setReCaptchaValid] = React.useState(false);
  const [reCaptchaMessage, setReCaptchaMessage] = React.useState(false);
  const [error, setError] = React.useState("");
  const [formData, setFormData] = React.useState({
    FNAME: "",
    LNAME: "",
    EMAIL: "",
    POSTALCODE: "",
    RES_TYPE: "Rent",
    PROJECT: "Bellmore Cove",

    //checkboxes
    "group[260][1]": "",
    "group[260][2]": "2",
    "group[260][3]": "",
    "group[260][4]": "",
    "group[260][5]": "",
    "group[260][6]": "",
  });

  const [validationErrors, setValidationErrors] = React.useState({
    FNAME: null,
    LNAME: null,
    EMAIL: null,
    POSTALCODE: null,
    RES_TYPE: null,
    HOMETYPE: null,
    PROJECT: null,
  });
  // React.useEffect(() => {
  //   console.log(validationErrors);
  // }, [validationErrors]);

  const handleOnFormChange = e => {
    // const isUncheckedCheckbox =
    //   e?.currentTarget?.attributes["type"].value === "checkbox" &&
    //   e?.currentTarget?.checked === false;
    const key = [
      Object.keys(formData).filter(key => {
        if (key === e?.currentTarget?.attributes?.name?.value) {
          return e?.currentTarget?.attributes?.name?.value;
        }
      }),
    ];

    setFormData({
      ...formData,
      [key]: e?.currentTarget?.value,
      // [key]: isUncheckedCheckbox ? "" : e?.currentTarget?.value,
    });
  };

  const handleFormErrorClose = () => {
    setError("");
  };

  function onChange(value) {
    setReCaptchaValid(true);
    setReCaptchaMessage(false);
    return true;
  }

  function onErrored() {
    console.error(`Error validating reCaptcha. Check network connection.`);
    return false;
  }

  const handleFormValidation = async formElements => {
    await validateForm(formElements, validationErrors, setValidationErrors, setSending);
  };

  const handleOnSubmit = event => {
    event.preventDefault();
    setSending(true);

    handleFormValidation(event.target.elements);

    if (!reCaptchaValid) {
      setReCaptchaMessage(true);
      setSending(false);
      console.error("reCaptcha not valid");
      return false;
    }

    axios({
      method: "POST",
      url: `https://epmoncaaz8.execute-api.us-east-1.amazonaws.com/default/ClearwaterMailChimp`,
      headers: {
        "Content-Type": "text/plain",
      },
      data: formData,
    })
      .then(res => {
        if (res.data.hasOwnProperty("_links")) {
          //setSending=false;
          setSending(false);
          setMessageSent(true);
          window.scrollTo(0, 0);
          Event("Registration", "Submitted", "Success");
          return true;
        }
        if (res.data.hasOwnProperty("status")) {
          if (res.data.title === "Member Exists") {
            const regex = /list member./gi;
            const detail = res.data.detail;
            const message = detail.substring(0, detail.search(regex) + 12);

            setValidationErrors(prev => ({ ...prev, EMAIL: message }));
            setSending(false);
            Event("Registration", "Submitted", "Member Exists");
            return false;
          }

          setSending(false);
          return false;
        }
      })
      .catch(error => {
        setSending(false);
        console.error(error.response.data);
      });

    return true;
  };

  return (
    <StyledSignUp component={Container} container maxWidth="xl" justifyContent="center">
      <form
        onSubmit={handleOnSubmit}
        id="registration"
        // action="https://oliveron10th.us20.list-manage.com/subscribe/post"
        // method="post"
        // validate="true"
      >
        <Grid item xs={10} className="indicates-required">
          <span className="asterisk">*</span> indicates required
        </Grid>
        {textFormFields.map((field, index) => {
          const thisClass = field.required ? "required" : null;
          return (
            <Grid item xs={12} md={5} pl={{ md: 1 }} pr={{ md: 1 }} key={field.name}>
              <TextField
                onChange={handleOnFormChange}
                value={formData[field.name] || ""}
                name={field.name}
                id={`mce-${field.name}`}
                className={`required ${validationErrors[field.name] ? "error" : ""}`}
                inputProps={{ className: thisClass }}
                label={field.label}
                variant="filled"
                type={field.type}
                required={field.required}
                helperText={validationErrors[field.name]}
                fullWidth
              />
              {/* {validationErrors[field.name] && (
                  <Typography style={{ color: "red" }} variant="caption">
                    {validationErrors[field.name]}
                  </Typography>
                )} */}
            </Grid>
          );
        })}
        {hiddenFormFields.map((field, index) => {
          return (
            <input type="hidden" id={`mce-${field.name}`} name={field.name} value={field.value} />
          );
        })}
        {/* {checkboxFormFields.map((field, index) => {
          // MuiRadio-root is the class that is controlling these

          return (
            <Grid
              item
              xs={10}
              md={5}
              pl={{ xs: 0, sm: 1 }}
              pr={{ xs: 0, sm: 1 }}
              p={2}
              key={field.name}
              mr={{ xs: "auto", sm: "unset" }}
            >
              <FormHelperText> {validationErrors["HOMETYPE"]}</FormHelperText>
              <FormControl
                component="fieldset"
                style={field.style}
                required={field.required}
                className={`required ${validationErrors["HOMETYPE"] ? "error" : null}`}
                name="HOMETYPE"
              >
                <FormLabel classes={{ root: "radio-label" }} component="legend">
                  {field.label}
                </FormLabel>

                <FormGroup>
                  {field.values.map((value, index) => {
                    return (
                      <FormControlLabel
                        key={value.value}
                        helperText={validationErrors["HOMETYPE"]}
                        control={
                          <Checkbox
                            id={`mce-${field.name}-260-${index}`}
                            name={`${field.name}[${index + 1}]`}
                            value={value.value}
                            // sx={{
                            //   color: "white",
                            //   "&.Mui-checked": {
                            //     color: "white",
                            //   },
                            // }}
                          />
                        }
                        onChange={handleOnFormChange}
                        label={value.label}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            </Grid>
          );
        })} */}
        {/* {radioFormFields.map((field, index) => {
          // MuiRadio-root is the class that is controlling these
          return (
            <Grid
              item
              xs={10}
              md={5}
              pl={{ xs: 0, sm: 1 }}
              pr={{ xs: 0, sm: 1 }}
              p={2}
              key={field.name}
              mr={{ xs: "auto", sm: "unset" }}
            >
              <FormHelperText> {validationErrors[field.name]}</FormHelperText>
              <FormControl
                component="fieldset"
                classes={{ root: "radio-fieldset" }}
                className={`required ${validationErrors[field.name] ? "error" : null}`}
                style={field.style}
                required={field.required}
              >
                <FormLabel classes={{ root: "radio-label" }} component="legend">
                  {field.label}
                </FormLabel>

                <RadioGroup
                  aria-label={field.label}
                  name={field.name}
                  value={formData[field.name]}
                  onChange={handleOnFormChange}
                  // color="common.white"
                  row={field.row}
                  helperText={validationErrors[field.name]}
                >
                  {field.values.map((value, index) => {
                    return (
                      <FormControlLabel
                        key={`${field.name}${value}`}
                        value={value}
                        control={
                          <Radio
                          // sx={{
                          //   color: "rgb(255,255,255)",
                          //   "&.Mui-checked": {
                          //     color: "rgb(255,255,255)",
                          //   },
                          // }}
                          />
                        }
                        label={value}
                        // color="white"
                      />
                    );
                  })}
                </RadioGroup>
              </FormControl>
            </Grid>
          );
        })} */}

        <Grid item xs={12} m={{ xs: 2, sm: 4 }} align="center">
          <ReCAPTCHA
            className={`captcha ${reCaptchaMessage ? "captchaError" : ""}`}
            sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
            onChange={onChange}
            onErrored={onErrored}
            style={{ marginBottom: "1rem" }}
          />
        </Grid>
        <Grid item xs={12} align="center">
          <Button
            type="submit"
            variant="contained"
            size="large"
            color="secondary"
            startIcon={
              sending && (
                <CircularProgress
                  variant="indeterminate"
                  thickness={6}
                  color="inherit"
                  size="1rem"
                />
              )
            }
          >
            {sending && <>Sending...</>}
            {!sending && <>Register for Updates</>}
          </Button>
        </Grid>
        {error.length > 0 && (
          <MuiAlert onClose={handleFormErrorClose} severity="error">
            <Typography variant="h5" color="textPrimary">
              ERROR! Message not sent!
            </Typography>
            <Typography variant="p" color="textPrimary">
              {error}
            </Typography>
          </MuiAlert>
        )}
      </form>
    </StyledSignUp>
  );
}

const StyledSignUp = styled(Grid)`
  font: 14px Helvetica, Arial, sans-serif;
  color: inherit;

  iframe {
    display: inline-block;
  }
  /* margin-left: 8%; */
  margin-top: 2rem;
  @media only screen and (min-device-width: 0px) and (max-device-width: 480px) {
    margin-top: 1rem;
  }
  .indicates-required {
    text-align: left;
  }
  .captchaError > div > div {
    border: red 4px solid;
  }

  .error > div {
    border: red 4px solid;
  }
  .size1of2 {
    min-width: 260px;
  }

  .MuiButton-root {
    border-radius: 50px;
    padding: 1rem 2rem; 
    font-weight: 600;
    ${props => props.theme.breakpoints.up("sm")} {
      min-width: 320px;
    }
    ${props => props.theme.breakpoints.up("md")} {
      min-width: 330px;
    }
  }
  /* .MuiButton-contained {
    background-color: ${props => props.theme.palette.secondary.main};
  } */

  form {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    /* max-width: 720px; */
    height: auto;
    flex-wrap: wrap;
  }
  
  .MuiFormHelperText-root {
    font-size: 0.8rem;
    // color: rgb(255 255 255 / 100%);
  }

  .MuiFormLabel-root {
    font-size: 1.1rem;
  }

  .MuiFilledInput-root {
    color: rgba(43, 63, 78, 1);
    background-color: white;
    &:hover {
      background-color: white;
    }
  }

  .MuiFilledInput-root.Mui-focused {
    background-color: white;
  }
  .MuiFilledInput-root:after,
  .MuiFilledInput-root:before {
    border-bottom: ${({ theme }) => theme.palette.primary.main};
    border-bottom-width: 2px;
  }
  .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: 2px solid ${({ theme }) => theme.palette.primary.main};
  }
  // .MuiInputBase-input {
  //   min-width: 305px;
  //   ${props => props.theme.breakpoints.up("sm")} {
  //     min-width: 300px;
  //   }
  //   ${props => props.theme.breakpoints.up("md")} {
  //     min-width: 500px;
  //   }
  // }
  .radio-label,
  .MuiIconButton-label,
  .MuiFormControlLabel-label {
    color: ${props => {
      // return props.theme.palette.common.white;
      return null;
    }}!important;
  }
  .MuiFormControlLabel-label {
    margin-left: 0.4rem;
  }
  .MuiFormControlLabel-root {
    margin: 0.3rem;
  }
  .MuiTextField-root {
    margin: 0.5rem 0;
    max
  }
  .MuiFormGroup-root {
    padding-left: 1rem;
  }

  .radio-fieldset {
    // margin: 2rem;
    flex-direction: row;
    > .radio-label {
      margin-bottom: 0.5rem;
    }
  }

`;

export default MailChimpForm;
