import React from "react";
import { Grid, Container, Typography } from "@mui/material";

function Video(props) {
  return (
    <Container disableGutters>
      <Grid
        m={{ sm: "0 auto" }}
        p={{ xs: "0 1rem 2rem", sm: "4rem 0", md: "8rem 0 6rem" }}
        container
        direction="column"
        disableGutters
      >
        <Typography variant="h3" sx={{ maxWidth: { xs: 300, sm: "initial" } }}>
          clearwater park - Everyone’s Home!
        </Typography>
        <hr color="#ADB99B" size="3px" style={{ marginBottom: "1.5rem", width: "100%" }} />
        <div
          className="wistia_responsive_padding"
          style={{ padding: "56.25% 0 0 0", position: "relative" }}
        >
          <div
            className="wistia_responsive_wrapper"
            style={{
              height: "100%",
              left: 0,
              position: "absolute",
              top: 0,
              width: "100%",
            }}
          >
            <div
              className="wistia_embed wistia_async_f6rew5okdo videoFoam=true"
              style={{ height: "100%", position: "relative", width: "100%" }}
            >
              &nbsp;
            </div>
          </div>
        </div>
      </Grid>
    </Container>
  );
}

export default Video;
