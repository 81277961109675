import React from "react";
import styled from "@emotion/styled";
import { Grid, Typography, Container, Box, Card, CardContent } from "@mui/material";

import Description from "./Description";
import FloorplansWave from "./svg/floorplansWave.svg";
import LowerLevelFloorplan from "./images/LowerLevelFloorplan.png";
import ThreeBedFloorplan from "./images/ThreeBedFloorplan.png";
import TwoBedLowerLevelFloorplan from "./images/TwoBedLowerLevelFloorplan.png";

export default function Floorplans() {
  return (
    <Container maxWidth={false} disableGutters>
      <Description />
      <Wrapper maxWidth={false} id="floorplans">
        <Grid container direction={"row"} columnGap={4} rowGap={4} justifyContent={"center"}>
          <Grid component={Card} item xs={12} md={3.5} borderRadius={3}>
            <ImageWrapper src={TwoBedLowerLevelFloorplan} />
            <Box bgcolor={"common.white"} textAlign={"center"} p={"2rem"}>
              <Typography variant="body2">2 Bed Lower Level</Typography>
            </Box>
          </Grid>
          <Grid component={Card} item xs={12} md={3.5} borderRadius={3}>
            <ImageWrapper src={LowerLevelFloorplan} />
            <Box bgcolor={"common.white"} textAlign={"center"} p={"2rem"}>
              <Typography variant="body2">Main Level</Typography>
            </Box>
          </Grid>
          <Grid component={Card} item xs={12} md={3.5} borderRadius={3}>
            <ImageWrapper src={ThreeBedFloorplan} />
            <Box bgcolor={"common.white"} textAlign={"center"} p={"2rem"}>
              <Typography variant="body2">3 Bed Lower Level</Typography>
            </Box>
          </Grid>
        </Grid>
        <Typography variant="body1" align="center" mt={"2rem"} maxWidth={"unset"}>
          One bedroom unit floorplan – COMING SOON
        </Typography>
      </Wrapper>
    </Container>
  );
}
const Wrapper = styled(Container)`
  background-image: url(${FloorplansWave}),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 10rem,
      rgba(234, 239, 238, 1) 10rem,
      rgba(234, 239, 238, 1) 100%
    );
  background-position: top 5rem center;
  background-size: contain;
  background-repeat: no-repeat;
  padding-top: 5rem;
  padding-bottom: 2rem;

  ${props => props.theme.breakpoints.up("md")} {
    background-image: url(${FloorplansWave});
    background-position: bottom center;
  }
`;
const ImageWrapper = styled("img")`
  width: 100%;
  height: auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
`;
